import SwalControlar, { SecureRequest } from "../assets/js/utils.js";
// const baseUrl = "https://abram.abram-sa.com/api/";
const baseUrl = "https://abram.abram.sa/api/";
const token = localStorage.getItem('token');
const Swal = new SwalControlar();
const RequestManager = new SecureRequest(token);

export {
    baseUrl,
    token,
    Swal,
    RequestManager
}

