import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Wallet {

    WalletInfo(state) {

        return RequestManager.get(`${baseUrl}alinma/analytical`)

            .then(response => {

                state(response.data);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    fetchRefundRequest(state) {

        return RequestManager.get(`${baseUrl}fund-transfer/request-amount/all`)

            .then(response => {

                state(response.data?.TransferMoneyRequestAll);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }
    fetchReversedAmounts(state) {

        return RequestManager.get(`${baseUrl}fund-transfer/withhel/all`)

            .then(response => {

                state(response.data?.WithheldMazadAmounts);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    fetchAuctionsAmount(state) {

        return RequestManager.get(`${baseUrl}fund-transfer/winning/all`)

            .then(response => {

                state(response.data?.WinningMazadDeductionsServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    updateFundRequest(data) {

        return RequestManager.post(`${baseUrl}fund-transfer/sarie`, data)

            .then(response => {

                Swal.success('تم بنجاح', `تم التعديل بنجاح`).then(_ => window.location.reload())

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }
    deleteAuctionAmountRequest(id) {

        return RequestManager.delete(`${baseUrl}fund-transfer/winning/delete/${id}`)

            .then(response => {


            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }
    deleteFundRequest(id) {

        return RequestManager.delete(`${baseUrl}fund-transfer/request-amount/delete/${id}`)

            .then(response => {


            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

}

export {
    Wallet
}