import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bell from '../../assets/SVGS/bell.svg';
import { Notficaitions } from '../../components';
import { useDataGetter } from './data';

export default function AdminHeader({ isExpanded, setIsExpanded }) {

    const {
        logoutVisible,
        visible,
        setVisible,
        setLogoutVisible,
        data,
        logOut
    } = useDataGetter();

    const user = JSON.parse(sessionStorage.getItem('user'));

    return (
        <header className='bg-white rounded-[10px] relative z-[10] p-3 sm:px-10 flex items-center justify-between mb-5'>

            <div className='flex items-center'>

                <div onClick={e => {
                    e.stopPropagation();
                    setIsExpanded(true);
                }} className='lg:hidden flex items-center justify-center border border-[var(--dark-gray-light)] p-1 cursor-pointer me-3 w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]' style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)' }}>

                    <FontAwesomeIcon icon={faBars} />

                </div>

                <h1 className='text-[var(--primary)] font-medium text-[11px] sm:text-[14px] md:text-[20px]'>مرحباً بك مجدداً {user?.name}  🎉</h1>

            </div>

            <div className='flex items-center'>

                <div className='relative me-3 hidden sm:block'>

                    <input type='text' className='border border-[#aba8d0] w-full lg:w-[250px] xl:w-[415px] rounded-full p-[3px] px-3' />

                    <FontAwesomeIcon icon={faSearch} className='absolute left-3 top-[50%] translate-y-[-50%] text-[#aba8d0]' />

                </div>

                <div onClick={e => {
                    e.stopPropagation();
                    setVisible(perv => !perv);
                }} className='cursor-pointer relative flex items-center justify-center min-w-[35px] h-[35px] rounded-full border border-[var(--blue-bell)] '>

                    <img src={bell} alt='' width={'23px'} height={'23px'} />

                    {
                        data?.totalNullReadAt
                            ?
                            <div className='w-[10px] h-[10px] rounded-full bg-[var(--coral)] absolute -top-1 right-0'></div>
                            :
                            null
                    }

                </div>

                <div onClick={e => {
                    e.stopPropagation();
                    setLogoutVisible(perv => !perv);
                }} className='flex items-center justify-center border border-[var(--dark-gray-light)] p-1 cursor-pointer ms-3 min-w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]' style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)' }}>

                    <img src={require('../../assets/images/default.jpg')} alt='' width={'25px'} height={'25px'} className='w-full h-full border border-[var(--dark-gray-light)] rounded-full ' />

                </div>

            </div>

            <div onClick={e => e.stopPropagation()} className={`${logoutVisible ? "opacity-100 visible scale-100" : "opacity-0 invisible scale-0"} max-h-[500px] overflow-y-auto transition w-fit absolute bg-white left-2 top-16 p-3 border border-[var(--blue-bell)] rounded-[10px]`}>

                <h5 className='text-center cursor-pointer text-[var(--blue-bell)]' onClick={logOut}>تسجيل إلخروج</h5>

            </div>

            <Notficaitions visible={visible} data={data} />

        </header >
    )
}
