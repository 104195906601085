import { dateFormat, handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Auctions {

    constructor(setIsLoading) {

        this.setIsLoading = setIsLoading;

    }

    fetchAuctions(state) {

        return RequestManager.get(`${baseUrl}auctions/main-auction`)

            .then(response => {

                return state(response.data?.MainAuctionServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    getSingleAuction(state, id) {

        return RequestManager.get(`${baseUrl}auctions/main-auction/edit/${id}`)

            .then(response => {

                return state(response.data?.MainAuctionServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteAuction(auctionId, list, setList, navigate) {

        return RequestManager.delete(`${baseUrl}auctions/main-auction/delete/${auctionId}`)

            .then(response => {

                if (list) {

                    const removeItemFromList = list.filter(item => item?.id != auctionId);

                    setList(removeItemFromList);

                }

                if (navigate) {

                    return Swal.success('تم بنجاح!', `تم حذف المزاد بنجاح`).then(_ => navigate('/auctions'))

                }


            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    editAuctions(data, id) {

        if (data.start_date) {

            data.start_date = dateFormat(data.start_date);

        }

        if (data.end_date) {

            data.end_date = dateFormat(data.end_date);

        }

        return RequestManager.update(`${baseUrl}auctions/main-auction/update/${id}`, data, 'post', true)

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


    fetchNotes(state) {

        return RequestManager.get(`${baseUrl}notes`)

            .then(response => {

                return state(response.data?.NotesServiecs);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    addNote(data, setNotes) {

        return RequestManager.post(`${baseUrl}notes/store`, data)

            .then(response => {

                const newNote = response.data?.NotesServiecs;

                setNotes(perv => ([...perv, newNote]));

                Swal.success('تم بنجاح!', `تم إضافة الملاحظة بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateNote(id, data) {

        return RequestManager.update(`${baseUrl}notes/update/${id}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    deleteNotes(noteId, list, setList) {

        return RequestManager.delete(`${baseUrl}notes/delete/${noteId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != noteId);

                setList(removeItemFromList);

                return Swal.success(
                    'تم الحذف',
                    'قمت بحذف الملاحظة بنجاح',
                );

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    allOrigins(state) {

        return RequestManager.get(`${baseUrl}notes/main-sub-pivot-ids`)

            .then(response => {

                const arctData = response.data.map(item => {
                    return { ...item, lable: `${item?.title} _ ${item?.id}` };
                })

                return state(arctData);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }
    updateAuction(id, status, staticRoute, row) {

        return RequestManager.update(`${baseUrl}${staticRoute ? staticRoute : "information-update-status/"}${id}`, { status }, 'put')

            .then(response => {

                status === 'APPROVED' ? row.status = 'APPROVED' : row.status = 'Rejected'

                Swal.success('تم بنجاح', `تم التعديل بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

}

export {
    Auctions
}