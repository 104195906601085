import { Header } from "../../layouts";

export default function MainContent({ isRenderLayouts, isLogin, children, isExpanded, setIsExpanded }) {

    return (
        isRenderLayouts && isLogin
            ?
            <div className={`p-5 bg-[var(--dark-gray-light)] lg:ms-[300px]`} style={{ transition: "0.5s" }}>

                <div className='container'>

                    {isRenderLayouts ? <Header isExpanded={isExpanded} setIsExpanded={setIsExpanded} /> : null}

                    {children}

                </div>

            </div>
            :
            children
    )
}
