function getTimeDifference(backendDate) {
    const backendTimestamp = new Date(backendDate).getTime();
    const currentTimestamp = new Date().getTime();
    const timeDifference = currentTimestamp - backendTimestamp;

    const absoluteTimeDifference = Math.abs(timeDifference); // Use the absolute value

    const years = Math.floor(absoluteTimeDifference / (1000 * 60 * 60 * 24 * 365.25));
    const months = Math.floor(absoluteTimeDifference / (1000 * 60 * 60 * 24 * 30.44));
    const weeks = Math.floor(absoluteTimeDifference / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(absoluteTimeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(absoluteTimeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(absoluteTimeDifference / (1000 * 60));

    let result = '';

    if (timeDifference < 0) {
        result += 'قبل ';
    }

    if (years >= 1) {
        result += `${years} ${years === 1 ? 'عام' : 'أعوام'}`;
    } else if (months >= 1) {
        result += `${months} ${months === 1 ? 'شهر' : 'أشهر'}`;
    } else if (weeks >= 1) {
        result += `${weeks} ${weeks === 1 ? 'إسبوع' : 'أسابيع'}`;
    } else if (days >= 1) {
        result += `${days} ${days === 1 ? 'يوم' : 'أيام'}`;
    } else if (hours >= 1) {
        result += `${hours} ${hours === 1 ? 'ساعة' : 'ساعات'}`;
    } else {
        result += `${minutes} ${minutes === 1 ? 'دقيقة' : 'دقائق'}`;
    }

    return result;
}

export {
    getTimeDifference
}
