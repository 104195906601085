import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Coustmers {

    fetchAuctionPublishers(state) {

        return RequestManager.get(`${baseUrl}all-information/member`)

            .then(response => {

                return state(response.data?.InformationServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    fetchSingleAuctionPublisher(state, id) {

        return RequestManager.get(`${baseUrl}auth/profile/${id}`)

            .then(response => {

                state({ ...response?.data?.Profile, ...response.data?.SocialMedia });

                return response.data;

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateAuctionPublisher(data, id, url) {

        return RequestManager.update(`${baseUrl}auth/profile/update/${id}`, data, 'post', true)

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل المزاد بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });


    }

    deleteAuctionPublisher(national_id, list, setList) {

        return RequestManager.delete(`${baseUrl}auth/profile/delete/${national_id}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.national_id != national_id);

                setList(removeItemFromList);

                return Swal.success(
                    'تم الحذف',
                    'قمت بحذف المزاد بنجاح',
                );

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    fetchBidders(state) {

        return RequestManager.get(`${baseUrl}all-information/user`)

            .then(response => {

                return state(response.data?.InformationServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteBidder(BidderId, list, setList) {

        return RequestManager.delete(`${baseUrl}auth/profile/delete/${BidderId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.national_id != BidderId);

                setList(removeItemFromList);

                return Swal.success(
                    'تم الحذف',
                    'قمت بحذف ناشر المزاد بنجاح',
                );

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

}

export {
    Coustmers
}