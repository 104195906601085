import React, { useContext, useEffect } from 'react'
import { Auctions, Wallet } from '../../apis/apis';
import { AppContext } from '../AppContext/AppContext';

export default function UpdateStatus({ classNames, showPopup, setShowPopup, row, staticRoute, asWaleet }) {

    const auctionUtailty = new Auctions();

    const waleetUtailty = new Wallet();

    const { setIsLoading } = useContext(AppContext);

    const handleSubmit = status => {

        const id = staticRoute ? row?.id : row?.national_id;

        setIsLoading(true);

        if (asWaleet) {

            waleetUtailty.updateFundRequest({ fundTransfersId: row?.id, statusTransfer: status })
                .finally(_ => {
                    setIsLoading(false);
                });

        } else {

            auctionUtailty.updateAuction(id, status, staticRoute, row)
                .finally(_ => setIsLoading(false));

        }


    }

    useEffect(() => {

        const handleHideMnue = () => setShowPopup(false);

        window.addEventListener('click', handleHideMnue);

        return () => window.removeEventListener('click', handleHideMnue);

    }, []);

    return (
        <ul onClick={e => e.stopPropagation()} className={`${showPopup ? "scale-100 visible opacity-100" : "scale-0 invisible opacity-0"} transition border border-[#EAEAFA] rounded-[5px] absolute text-[10px] font-bold -left-16 top-10 z-[100] w-[100px] mb-10 cursor-pointer bg-white ${classNames}`} style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)' }}>

            <li onClick={_ => handleSubmit('APPROVED')} className='p-3 border-b border-b-[#EAEAFA]'>قبول</li>
            <li onClick={_ => handleSubmit('REJECTED')} className='p-3 border-b border-b-[#EAEAFA]'>رفض</li>

        </ul>
    )

}
