import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class TermsAndConditions {

    fetchTermsAndConditions(state) {

        return RequestManager.get(`${baseUrl}legal/docs/terms-condition`)

            .then(response => {

                state(response.data?.TermsAndConditionServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }
    addTermsAndConditions(data, setConditions) {

        return RequestManager.post(`${baseUrl}legal/docs/terms-condition/store`, data)

            .then(response => {

                const newCondition = response.data?.TermsAndConditionServices;

                setConditions(perv => ([...perv, newCondition]));

                Swal.success('تم بنجاح!', `تم اضافه الشرط بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    updateTerm(id, data) {

        return RequestManager.update(`${baseUrl}legal/docs/terms-condition/update/${id}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    deleteTermsAndConditions(conditionId, list, setList) {

        return RequestManager.delete(`${baseUrl}legal/docs/terms-condition/delete/${conditionId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != conditionId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم حذف الشرط`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }


}

export {
    TermsAndConditions
}