import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Employee {

    fetchEmployees(state) {

        return RequestManager.get(`${baseUrl}all-information/admin`)

            .then(response => {

                return state(response.data?.InformationServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    fetchSingleEmployee(employeeId, state) {

        return RequestManager.get(`${baseUrl}auth/profile/${employeeId}`)

            .then(response => {

                return state(response.data?.Profile);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    addEmployee(data, setData) {

        return RequestManager.post(`${baseUrl}auth/register/admin`, data)

            .then(response => {

                Swal.success('تم بنجاح!', `تم حذف المزاد بنجاح`);

                return setData(perv => ([...perv, response.data?.registration]));

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    editEmployee(employeeId, data, navigate) {

        return RequestManager.update(`${baseUrl}auth/profile/update/${employeeId}`, data)

            .then(response => {

                return Swal.success('تم بنجاح!', `تم حذف المزاد بنجاح`).then(_ => navigate('/employees'));

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteEmployee(employeeId, list, setList) {

        return RequestManager.delete(`${baseUrl}auth/profile/delete/${employeeId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.national_id != employeeId);

                setList(removeItemFromList);

                return Swal.fire(
                    'تم الحذف',
                    'قمت بحذف الموظف بنجاح',
                    'success'
                );

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

}

export {
    Employee
}