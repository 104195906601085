import Aos from "aos";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { AppContext } from "../components/AppContext/AppContext";
import { isLogin } from "../assets/js/utils";
import { Auth } from "../apis/Auth/Auth";

const useHideMnueByClickToWindow = (isExpanded, setIsExpanded) => {

    const location = useLocation().pathname;

    // ** Function to handle window resize
    const hendlResize = () => {

        if (window.innerWidth <= 1024) {

            setIsExpanded(false);

        } else {

            setIsExpanded(true);

        }
    }

    const handelHideMnue = () => {

        if (isExpanded) {

            setIsExpanded(false);

        }

    }

    useEffect(() => {
        // Create a new link element to represent the favicon
        const headIcon = document.createElement('link');

        // Select all existing link elements in the head with rel="icon"
        const removeOldestLogo = document.querySelectorAll('head link[rel="icon"]');

        // Remove all existing favicon link elements
        removeOldestLogo.forEach((item) => item.remove());

        // Set the rel and href attributes of the newly created link element
        headIcon.setAttribute('rel', 'icon');
        headIcon.setAttribute('href', require('./logo.png'));

        // Append the new link element to the head of the document
        document.head.appendChild(headIcon);

    }, [location]);

    useEffect(() => {

        window.addEventListener('resize', hendlResize);

        hendlResize();

        // Clean Up
        return () => window.removeEventListener('resize', hendlResize);

    }, [location]);

    useEffect(() => {

        if (window.innerWidth < 1025) {

            window.addEventListener('click', handelHideMnue);

        }

        // Clean Up
        return () => window.removeEventListener('click', handelHideMnue);
    }, [isExpanded]);

}

const useUpdateImageProgress = (imagesUpload, setImagesUpload, activeIndex, setActiveIndex) => {
    useEffect(() => {
        if (imagesUpload.length === 0) {
            return; // No uploaded images, so exit the effect
        }

        const interval = setInterval(() => {

            setImagesUpload((prevState) => {

                const updatedImages = [...prevState];
                const activeImage = updatedImages[activeIndex];


                if (activeImage?.progress < 100) {

                    activeImage.progress += 10;

                    if (activeImage.progress >= 100) {

                        setActiveIndex((prevIndex) => prevIndex + 1);

                        activeImage.status = 'active';

                    }

                } else if (activeImage?.progress === 100) {

                    setActiveIndex((prevIndex) => prevIndex + 1);

                }

                return updatedImages;

            });
        }, 400); // Updated interval to 400 milliseconds

        // loop for images to check if the intervel end or not
        const isComplated = imagesUpload.every(image => image.progress === 100);

        if (isComplated) {

            return clearInterval(interval);

        }

        return () => {

            clearInterval(interval);

            imagesUpload.forEach((image) => URL.revokeObjectURL(image.url));

        };
    }, [imagesUpload, activeIndex]);
}

const useAppControlar = () => {

    const { isLoading } = useContext(AppContext);

    const authUtailty = new Auth();

    const [isExpanded, setIsExpanded] = useState(false);

    const navigate = useNavigate();

    const isLoggedIn = isLogin();

    const [isRenderLayouts, setIsRenderLayouts] = useState(true);

    // Hide Mnue When user Click On Window
    useHideMnueByClickToWindow(isExpanded, setIsExpanded);

    useEffect(() => {

        if (!isLoggedIn) navigate('/login');

        if (isLoggedIn) authUtailty.profile();

    }, []);

    useEffect(() => {

        const body = document.body;

        if (isLoading) {

            body.style.cssText += "max-height: 100vh; overflow:hidden";

        } else {

            body.style.cssText += "max-height: initial; overflow:initial";

        }

    }, [isLoading]);


    useEffect(() => {

        Aos.init({ duration: 1000 });

        Aos.refresh();

        // Clean Up
        return () => { };

    }, []);

    return { isRenderLayouts, setIsRenderLayouts, isExpanded, setIsExpanded };
}

export default function useCustomEffect() {
    return { useUpdateImageProgress, useAppControlar }
}
