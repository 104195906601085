import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Origins {


    updateOrigin(data, id) {

        data.old_images = data.images.filter(img => typeof img === "string");
        data.new_images = data.images.filter(img => typeof img !== "string");
        delete data.images;

        return RequestManager.update(`${baseUrl}auctions/sub-auction/update/${id}`, data, null, true)
            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل الاصل بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteOrigin(originId, navigate) {

        return RequestManager.delete(`${baseUrl}auctions/sub-auction/delete/${originId}`)

            .then(response => {

                Swal.success('تم بنجاح!', `تم حذف الاصل بنجاح`).then(resp => navigate('/auctions'));

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

}

export {
    Origins
}