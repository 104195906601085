import { RequestManager, Swal, baseUrl } from "../data";

class Auth {

    constructor(setLoader) {

        this.setLoader = setLoader;

    }

    postLogin(data) {

        return RequestManager.post(`${baseUrl}auth/login/admin`, data, false).then(response => {

            // append Data to Localstorage
            localStorage.setItem('token', response.data);
            Swal.success(null, response.data?.message || `تم تسجيل الدخول بنجاح`).then(_ => window.location.reload());

        })
            .catch(error => {

                Swal.rejected(null, error?.response?.data || `The given Data was invalid`);

            })

    }

    postLogout() {

        return RequestManager.post(`${baseUrl}auth/logout`).then(response => {

            return;

        })
            .catch(error => error);
    }

    profile() {

        RequestManager.get(`${baseUrl}information`, true).then(response => {

            if (response.data?.InformationServices?.role !== 'admin') {

                // Navigate him to login
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            }
            return sessionStorage.setItem('user', JSON.stringify(response.data?.InformationServices));

        })
            .catch(error => error);

    }

    panelInfo(state) {

        RequestManager.get(`${baseUrl}analytical/count`, true).then(response => {

            return state(response.data);

        })
            .catch(error => error);

    }


}

export {
    Auth
}