import { NavLink } from "react-router-dom";
import users from "../../../../assets/SVGS/users.svg";
import layers from "../../../../assets/SVGS/layers.svg";
import layout from "../../../../assets/SVGS/layout.svg";
import trello from "../../../../assets/SVGS/trello.svg";
import server from "../../../../assets/SVGS/server.svg";
import message from "../../../../assets/SVGS/message-square.svg";

const adminRoutes = data => {

    return (
        [
            {
                expanded: false,
                label: <div className='flex items-center relative'>

                    <img src={users} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>العملاء</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'auction-publishers'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>ناشري المزاد</span>

                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingMember}</div>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'auctions-creatores'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>مُزايدين</span>

                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingUser}</div>

                        </NavLink>,

                    },
                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <img src={layers} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>المزادات</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'auctions'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>المزادات</span>

                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingAuction}</div>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'auction/note'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>الملاحظات</span>

                        </NavLink>,

                    },
                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <img src={layout} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>طلبات المُزايده</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'requests'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>الطلبات</span>

                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingSubscription}</div>

                        </NavLink>,

                    },

                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <img src={trello} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>الصفقات</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'deals'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>جميع الصفقات</span>

                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingDeal}</div>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'edit-auction-price'} className='flex items-center  p-2 !w-full' end>

                            <span className='ms-3'>تعديل اسعار المُزايدين</span>

                        </NavLink>,

                    },

                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <img src={server} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>الموقع</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'terms'} className='flex items-center  p-2 !w-full' end>

                            <span className='ms-3'>الشروط و الاحكام</span>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'privacy'} className='flex items-center  p-2 !w-full' end>

                            <span className='ms-3'>سياية الخصوصية</span>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'copyrights'} className='flex items-center  p-2 !w-full' end>

                            <span className='ms-3'>سياسية حقوق الملكية الفكرية</span>

                        </NavLink>,

                    },
                    {
                        label: <NavLink to={'glabel-question'} className='flex items-center  p-2 !w-full' end>

                            <span className='ms-3'>الاسئلة الشائعة</span>

                        </NavLink>,

                    },

                ]
            },
            {
                expanded: false,
                label: <div className='flex items-center'>

                    <img src={message} alt='' width={'19px'} height={'19px'} />

                    <span className='ms-4 text-[var(--dark-slate-gray)]'>الرسائل</span>

                </div>,
                items: [
                    {
                        label: <NavLink to={'messages'} className='flex items-center relative p-2 !w-full' end>

                            <span className='ms-3'>الرسائل الواردة</span>


                            <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingMessage}</div>

                        </NavLink>,

                    }

                ]
            }

        ]
    )

}

export const items = data => adminRoutes(data);