import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Copyrights {

    fetchCopyrights(state) {

        return RequestManager.get(`${baseUrl}legal/docs/copyright-policy`)

            .then(response => {

                return state(response.data?.CopyrightPolicyServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    addCopyrights(data, setCopyrights) {

        return RequestManager.post(`${baseUrl}legal/docs/copyright-policy/store`, data)

            .then(response => {

                const newItem = response.data?.CopyrightPolicyServices;

                setCopyrights(perv => ([...perv, newItem]));

                Swal.success('تم بنجاح!', `تم اضافه الشرط بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateCopyright(id, data) {

        return RequestManager.update(`${baseUrl}legal/docs/copyright-policy/update/${id}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteCopyrights(cpyId, list, setList) {

        return RequestManager.delete(`${baseUrl}legal/docs/copyright-policy/delete/${cpyId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != cpyId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم حذف الشرط`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    Copyrights
}