import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal } from "../data";

class ConfiremEmail {

    getOtp() {

        return RequestManager.post(`https://abram.abram.sa/api/Verify/email`, null, true).then(response => {

            return response;

        }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

    postOtp(otp) {

        return RequestManager.post(`https://abram.abram.sa/api/Verify/email/store`, { otp }, true).then(response => {

            return response.data;

        }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });
    }

}

export {
    ConfiremEmail
}