import axios from "axios";
import Swal from "sweetalert2";

const uploadImageByLimt = (maxLength, inputId, allowedTypesFiles, notAllowdFilesError) => {

    const input = document.getElementById(inputId);

    input.addEventListener("change", function (e) {
        var files = e.target.files;
        var allowedTypes = allowedTypesFiles; // Specify the allowed image file types

        // Check if the number of selected files exceeds the limit
        if (files.length > maxLength) {
            Swal.fire({
                icon: "error",
                text: `Please select a maximum of ${maxLength} image files.`
            });
            this.value = null; // Clear the selected files
            return;
        }

        // Iterate through the selected files and check their types
        for (var i = 0; i < files.length; i++) {
            console.log(files[i].type)
            if (!allowedTypes.includes(files[i].type)) {
                Swal.fire({
                    icon: "error",
                    text: notAllowdFilesError
                });
                this.value = null; // Clear the selected files
                return;
            }
        }

        // Files passed the validation, you can proceed with further processing
        // ...
    });
}

const handleDelete = (callback) => {

    Swal.fire({
        title: 'هل انت متأكد',
        text: 'سوف تقوم بالحذف',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: "الغاء",
        confirmButtonText: "حذف"
    }).then((result) => {

        if (result.isConfirmed) callback();

    })

}

export default class SwalControlar {

    constructor() {

    }

    success(title, message) {

        return Swal.fire({
            icon: 'success',
            title: title || 'Good...',
            text: message,
        })

    }

    warning(title, message) {

        return Swal.fire({
            icon: 'warning',
            title: title || 'warning...',
            text: message,
        })

    }

    rejected(title, message) {

        return Swal.fire({
            icon: 'error',
            title: title || 'Oops...',
            text: message,
        });

    }
};

class SecureRequest {

    constructor(token) {

        this.token = token

    }

    auth(asFormData) {

        return {

            Authorization: `Bearer ${localStorage.getItem('token')}`,

            'Content-Type': asFormData ? "multipart/form-data" : "application/json"

        }

    }

    post(url, data, asFormData) {

        return axios({

            method: 'post',

            url: url,

            data: data,

            headers: this.auth(asFormData)

        })

    }

    get(url) {

        return axios({

            method: 'get',

            url: url,

            headers: this.auth()

        })

    }

    delete(url, data) {

        return axios({

            method: 'delete',

            url: url,

            data: data,

            headers: this.auth()

        })

    }

    update(url, data, method, asFormData) {

        return axios({

            method: method || 'post',

            url: url,

            data: data,

            headers: this.auth(asFormData),

        })

    }


}

const fireSwal = (icon, title, text, moreOptions) => {
    return Swal.fire({
        icon,
        title,
        text,
        ...moreOptions
    });
}

const getSelectedOption = (list, optionKey, value) => {
    return list?.filter(item => item[optionKey] == value)[0];
}

const handleDeleteItem = (handelDeleteFunction, rowData, list, setList) => {

    return Swal.fire({
        title: 'هل انت متأكد',
        text: 'سوف تقوم بالحذف',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: "الغاء",
        confirmButtonText: "حذف"
    }).then((result) => {
        if (result.isConfirmed) {

            handelDeleteFunction && handelDeleteFunction(rowData.id, list, setList);

        }
    });
}


const handleToggleFullScreen = (elem) => {

    elem.preventDefault();

    if (elem.target.requestFullscreen) {

        elem.target.requestFullscreen();

    } else if (elem.target.msRequestFullscreen) {

        elem.target.msRequestFullscreen();

    } else if (elem.target.mozRequestFullScreen) {

        elem.target.mozRequestFullScreen();

    } else if (elem.target.webkitRequestFullscreen) {

        elem.target.webkitRequestFullscreen();

    } else {

        Swal.fire("Sorry, your browser is too old and doesn't support fullscreen :-(");

    }

};


const cities = ['جدة', 'المدينة المنورة', 'جيزان', 'خميس مشيط', 'مكة المكرمة', 'الرياض', 'شرورة', 'نجران', 'الطايف', 'الدمام', 'الخبر', 'الجبيل', 'الاحساء',
    'حائل', 'القصيم', 'عرعر', 'الجوف', 'ينبع', 'ابها', 'حفر الباطن', 'الباحة', 'تبوك'];


const auctionselectedType = [
    'إفتراضي',
    'حضوري',
    'إفتراضي حضوري'
];


const dateFormat = (currentDate) => {

    const date = new Date(currentDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;

}

const isLogin = _ => {
    const token = localStorage.getItem('token');
    return token ? true : false;
}

// Handle User Logout
const handleLogOut = (response, userWant) => {

    if (response?.status == 401 || response?.status == 403  || userWant) {

        window.localStorage.clear();

        return window.location.href = "/login";

    }

}

const numberFormat = number => {
    let formattedNumber = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
}


export {
    uploadImageByLimt,
    handleDeleteItem,
    SecureRequest,
    handleToggleFullScreen,
    isLogin,
    getSelectedOption,
    cities,
    auctionselectedType,
    dateFormat,
    handleLogOut,
    fireSwal,
    numberFormat,
    handleDelete,
}