import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function TableHeader({ globalFilterValue, onGlobalFilterChange, HandleExport }) {

    return (
        <div className='flex sm:items-center sm:justify-between flex-col sm:flex-row px-10 mb-5'>

            <button className='mb-5 sm:mb-0 sm:me-6 font-medium text-[12px] xl:text-[16px] border p-1 px-10 rounded-[5px] text-[#AEC1C5] text-center !shadow-none' onClick={HandleExport}>تصدير</button>

            <div className='relative flex-1 md:max-w-[400px]'>

                <input type='text' placeholder='ابحث' value={globalFilterValue} onChange={onGlobalFilterChange} className='px-10 py-3 rounded-full border w-full' />

                <FontAwesomeIcon icon={faSearch} className='text-[#252525] text-[13px] absolute left-4 top-[50%] translate-y-[-50%]' />

            </div>


        </div>
    )
}
