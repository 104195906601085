import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class RequestsUtailty {


    fetchRequests(state) {

        return RequestManager.get(`${baseUrl}bidder/management/subscription`)

            .then(response => {

                return state(response.data?.BidderSubscriptionServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    RequestsUtailty
}