import { Column } from "primereact/column";
import { useState } from "react";
import { FilterMatchMode } from 'primereact/api';

const useDataGetter = (list) => {

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    return {
        filters,
        onGlobalFilterChange,
        globalFilterValue
    }

}

const data = (columns,) => {

    return columns?.map(column => {

        return <Column field={column?.field} sortable={column?.header} header={column?.header} className={column?.classNames} body={column?.tamplate} />;

    })

}

const FilterFields = [
    'auction_title',
    'name',
    'email',
    'Location_country',
    'country',
    'national_id',
    'phone',
    'city',
    'Location_city',
    'nafath',
    'start_date',
    'end_date',
    'license_number',
    'auction_type',
    'asset_number',
    'AuctionTitle',
    'SubAuctionTitle',
    'MemberPublishName',
    'MainAuctionTitle',
    'amount',
]

export {
    useDataGetter,
    data,
    FilterFields
}