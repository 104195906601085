import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Messages {

    fetchMessages(state) {

        return RequestManager.get(`${baseUrl}message/list`)

            .then(response => {

                return state(response.data?.MessageServices);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    replay(id, data) {

        return RequestManager.post(`${baseUrl}message/reply/${id}`, data)

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            })
            .catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteMessages(messagesId, list, setList) {

        return RequestManager.delete(`${baseUrl}message/delete/${messagesId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != messagesId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم حذف الرسالة`);

            })
            .catch(error => {
                console.log(error);
                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    Messages
}