import React, { Fragment, useEffect, useState } from 'react'
import "./components/index.css"
import { MnueMainContent, MnueHeader } from './components';
import { Auth } from '../../apis/Auth/Auth';

export default function MainPanel({ isExpanded, setIsExpanded }) {

    const [data, setData] = useState([]);

    const authUtailty = new Auth();

    useEffect(() => {

        authUtailty.panelInfo(setData);

    }, []);

    return (
        <Fragment>

            <div onClick={e => e.stopPropagation()} className={`main-menu top-0 z-[9999999] ${isExpanded ? "show" : "hide"} bg-white shadow-lg menu-fixed menu-accordion`} >

                <MnueHeader setIsExpanded={setIsExpanded} />

                <MnueMainContent data={data}></MnueMainContent>

            </div>

        </Fragment>
    )
}
