import { RenderSection } from "..";
import { useEffect, useRef, useState } from "react";
import { uploadImageByLimt } from "../../assets/js/utils";
import { handelChangeImageFileInput, renderImagesUpload } from "./data";
import useCustomEffect from "../../hooks/useCustomEffect";

export default function UploadImages({
    RenderSectionClassNames,
    maxLength,
    inputId,
    isDisabled,
    maxHeight,
    classNames,
    textStyle,
    titleText,
    allowedTypesFiles,
    notAllowdFilesError,
    typeAsImage,
    formik,
    imageType,
    arrOfOldImages,
    imagePath,
    attrName
}) {
    const [imagesUpload, setImagesUpload] = useState([]);

    const inputRef = useRef();

    const [activeIndex, setActiveIndex] = useState(0);

    const { useUpdateImageProgress } = useCustomEffect();

    const [imagesAsInputFiles, setImagesAsInputFiles] = useState([]);

    useEffect(() => {

        uploadImageByLimt(maxLength, inputId, allowedTypesFiles, notAllowdFilesError);

        // Clean Up
        return _ => { };

    }, []);

    useEffect(() => {

        imagePath && setImagesUpload(imagePath ? [{ url: imagePath, status: "active", name: "صوره المزاد", progress: 100, old: true }] : []);

    }, [imagePath]);

    useEffect(() => {

        arrOfOldImages && setImagesUpload(arrOfOldImages ? arrOfOldImages?.map(image => {
            const newItem = { url: image, status: "active", name: "صوره المزاد", progress: 100, old: true };
            return newItem;
        }) : []);

    }, [arrOfOldImages]);

    useUpdateImageProgress(imagesUpload, setImagesUpload, activeIndex, setActiveIndex, formik, attrName);

    const handleChange = e => {

        handelChangeImageFileInput(e, setImagesUpload, setActiveIndex, maxLength, setImagesAsInputFiles, inputRef);

        if (formik) {

            if (imageType === 'string') {

                formik.setFieldValue(attrName, e.target.files[0]);

            }

        }

    }

    useEffect(() => {

        if (imageType !== "string") {

            const getOldImagesAfterUpdate = imagesUpload?.filter(image => arrOfOldImages.includes(image.url)).map(item => item?.url);

            formik.setFieldValue(attrName, [...getOldImagesAfterUpdate, ...imagesAsInputFiles]);

        }


    }, [imagesAsInputFiles, imagesUpload])


    return (
        <RenderSection classNames={RenderSectionClassNames}>

            <div>

                <input disabled={isDisabled} ref={inputRef} onChange={handleChange} type="file" className="hide-input" multiple maxLength={3} id={inputId} />

                <label htmlFor={inputId} className={`w-full ${isDisabled ? "bg-[#ffffff] text-[#ccc] cursor-not-allowed" : "bg-white text-black cursor-pointer"} border-2 border-dashed rounded-[10px] flex items-center justify-center ${classNames}`} style={{ height: `${maxHeight}px` }} >

                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M18.6666 18.6667L13.9999 14L9.33325 18.6667" stroke={isDisabled ? "#ccc" : "#37495F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 14V24.5" stroke={isDisabled ? "#ccc" : "#37495F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.7883 21.4549C24.9262 20.8346 25.8251 19.853 26.3431 18.665C26.8612 17.477 26.9689 16.1504 26.6492 14.8944C26.3295 13.6384 25.6007 12.5247 24.5777 11.729C23.5548 10.9332 22.296 10.5008 21 10.4999H19.53C19.1768 9.13406 18.5186 7.866 17.6049 6.7911C16.6911 5.7162 15.5456 4.86243 14.2544 4.29398C12.9632 3.72553 11.5599 3.45719 10.1501 3.50914C8.74025 3.56108 7.36053 3.93196 6.11466 4.59388C4.86879 5.25581 3.78918 6.19156 2.95701 7.33079C2.12484 8.47001 1.56176 9.78307 1.3101 11.1712C1.05843 12.5594 1.12474 13.9866 1.50404 15.3454C1.88333 16.7043 2.56574 17.9594 3.49995 19.0166" stroke={isDisabled ? "#ccc" : "#37495F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.6666 18.6667L13.9999 14L9.33325 18.6667" stroke={isDisabled ? "#ccc" : "#37495F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <h3 className={`text-[var(--praimary)] ms-3 ${textStyle}`}>{titleText}</h3>

                </label>

            </div>

            {renderImagesUpload(imagesUpload, setImagesUpload, typeAsImage, setImagesAsInputFiles, isDisabled)}

        </RenderSection>
    )
}
