import { handleDeleteItem } from '../../assets/js/utils';
import trash from '../.../../../assets/SVGS/trash.svg';

export default function TrashIcon({ handelDeleteFunction, rowData, list, setList }) {

    return (
        <div onClick={_ => handleDeleteItem(handelDeleteFunction, rowData, list, setList)} className='w-[30px] h-[30px] me-5 bg-[#EFEFFF] text-[var(--primary)] rounded-full flex items-center justify-center'>

            <img src={trash} alt='' loading='lazy' width={''} height={''} className='cursor-pointer' />

        </div>
    )
}
