import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Deals {

    fetchDeals(state) {

        return RequestManager.get(`${baseUrl}deal/all`)

            .then(response => {

                return state(response.data?.DealsServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    fetchEditDeals(state) {

        return RequestManager.get(`${baseUrl}deal/all/edit`)

            .then(response => {

                return state(response.data?.DealsServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateDeals(dealId, data) {

        return RequestManager.update(`${baseUrl}deal/update-amount/${dealId}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل الاصل بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    deleteDeals(dealId, list, setList) {

        return RequestManager.delete(`${baseUrl}deal/delete-amount/${dealId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != dealId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم الحذف`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    Deals
}