import React from 'react'
import logo from '../../../../assets/SVGS/admin-logo.svg';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MnueHeader({ setIsExpanded }) {
    return (
        <div className={`w-full relative py-10 border-b flex items-center justify-center mb-10 transition-all `}>

            <img src={logo} alt='' loading='lazy' width={'25px'} height={'25px'} />

            <h1 className='text-[var(--primary)] text-[25px] ms-5'>Abram Dasboard</h1>

            <div onClick={e => setIsExpanded(false)} className='absolute -left-8 lg:hidden flex items-center justify-center border border-[var(--dark-gray-light)] p-1 cursor-pointer me-3 w-[35px] h-[35px] rounded-full bg-[var(--dark-gray-light)]' style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)' }}>

                <FontAwesomeIcon icon={faX} className='text-[11px]' />

            </div>

        </div >
    )
}
