// importing require modules
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { MainContent } from "../components";


// ##Lazy Function To Load Page Easly Importing From React 

// User Routes
import {
    Loader,
    Login,
    Analytics,
    Employees,
    EditEmployee,
    AuctionsCreatores,
    Auctions,
    EditAuction,
    AuctionPublishers,
    EditAuctionPublishers,
    Notes,
    Requests,
    AllDeals,
    EditAuctionPrice,
    Terms,
    Privacy,
    Copyrights,
    Wallet,
    Messages,
    Questions,
    NotFound,
} from './data';


export default function routes(isRenderLayouts, setIsRenderLayouts, isLogin, isExpanded, setIsExpanded) {

    const notFound = <NotFound classNames={'min-h-[100vh]'} setIsRenderLayouts={setIsRenderLayouts} notRenderLayout={true} />;

    return (
        // Prevent loading errors with fallback Loader
        <Suspense fallback={<Loader />}>

            <MainContent isRenderLayouts={isRenderLayouts} isLogin={isLogin} isExpanded={isExpanded} setIsExpanded={setIsExpanded} >

                {/* Route configuration */}
                <Routes>

                    {/* Start User Routes */}
                    <Route path="login" element={<Login />} />

                    <Route path="" element={isLogin ? <Analytics /> : notFound} />

                    <Route path="employees" element={isLogin ? <Employees /> : notFound} />

                    <Route path="edit-employee" element={isLogin ? <EditEmployee /> : notFound} />

                    <Route path="auction-publishers" element={isLogin ? <AuctionPublishers /> : notFound} />

                    <Route path="edit-auction-publishers" element={isLogin ? <EditAuctionPublishers /> : notFound} />

                    <Route path="edit-auction-bidder" element={isLogin ? <EditAuctionPublishers allowLincesNunber={true} /> : notFound} />

                    <Route path="auctions-creatores" element={isLogin ? <AuctionsCreatores /> : notFound} />

                    <Route path="auctions" element={isLogin ? <Auctions /> : notFound} />

                    <Route path="auction/note" element={isLogin ? <Notes /> : notFound} />

                    <Route path="requests" element={isLogin ? <Requests /> : notFound} />

                    <Route path="deals" element={isLogin ? <AllDeals /> : notFound} />

                    <Route path="edit-auction-price" element={isLogin ? <EditAuctionPrice /> : notFound} />

                    <Route path="auctions/view-auction" element={isLogin ? <EditAuction isDisapled={true} /> : notFound} />

                    <Route path="auctions/edit" element={isLogin ? <EditAuction isDisapled={false} /> : notFound} />

                    <Route path="terms" element={isLogin ? <Terms /> : notFound} />

                    <Route path="privacy" element={isLogin ? <Privacy /> : notFound} />

                    <Route path="copyrights" element={isLogin ? <Copyrights /> : notFound} />

                    <Route path="glabel-question" element={isLogin ? <Questions /> : notFound} />

                    <Route path="wallet" element={isLogin ? <Wallet /> : notFound} />

                    <Route path="messages" element={isLogin ? <Messages /> : notFound} />

                    <Route path="*" element={<NotFound classNames={'min-h-[100vh]'} setIsRenderLayouts={setIsRenderLayouts} notRenderLayout={true} />} />

                    {/* End Of Route configuration */}
                </Routes>

            </MainContent>

        </Suspense>
    );
}
