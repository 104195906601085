import { handleLogOut } from "../../assets/js/utils";
import { baseUrl, RequestManager, Swal } from "../data";

class ControlPanel {

    getAnalytical(state) {

        return RequestManager.get(`${baseUrl}analytical`).then(response => {

            state(response.data?.Analytical);

        }).catch(error => {

            Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

        })

    }

}

export {
    ControlPanel
}
