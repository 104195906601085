import { lazy } from 'react';

const Loader = lazy(() => import('../pages/Loader'));
const Login = lazy(() => import('../pages/Login'));
const Analytics = lazy(() => import('../pages/admin/Analytics'));
const Wallet = lazy(() => import('../pages/admin/Wallet'));
const Employees = lazy(() => import('../pages/admin/Employees'));
const EditEmployee = lazy(() => import('../pages/admin/EditEmployee'));
const AuctionsCreatores = lazy(() => import('../pages/admin/AuctionsCreatores'));
const Auctions = lazy(() => import('../pages/admin/Auctions'));
const EditAuction = lazy(() => import('../pages/admin/EditAuction'));
const AuctionPublishers = lazy(() => import('../pages/admin/AuctionPublishers'));
const EditAuctionPublishers = lazy(() => import('../pages/admin/EditAuctionPublishers'));
const Notes = lazy(() => import('../pages/admin/Notes'));
const Requests = lazy(() => import('../pages/admin/Requests'));
const AllDeals = lazy(() => import('../pages/admin/AllDeals'));
const EditAuctionPrice = lazy(() => import('../pages/admin/EditAuctionPrice'));
const Terms = lazy(() => import('../pages/admin/Terms'));
const Privacy = lazy(() => import('../pages/admin/Privacy'));
const Copyrights = lazy(() => import('../pages/admin/Copyrights'));
const Messages = lazy(() => import('../pages/admin/Messages'));
const Questions = lazy(() => import('../pages/admin/Questions'));
const NotFound = lazy(() => import('../pages/NotFound'));


export {
    Loader,
    Login,
    Analytics,
    Wallet,
    Employees,
    EditEmployee,
    AuctionsCreatores,
    Auctions,
    EditAuction,
    AuctionPublishers,
    EditAuctionPublishers,
    Notes,
    Requests,
    AllDeals,
    EditAuctionPrice,
    Terms,
    Privacy,
    Copyrights,
    Questions,
    Messages,
    NotFound
}