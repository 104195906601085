import React from 'react'
import { isLogin } from '../../assets/js/utils'
import { Navigate } from 'react-router-dom';

export default function Auth() {

    const loginStatus = isLogin();

    return (

        loginStatus
            ?
            null
            :
            <Navigate to={'/login'} />

    )
}
