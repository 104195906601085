import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Privacy {

    fetchPrivacy(state) {

        return RequestManager.get(`${baseUrl}legal/docs/privacy-policy`)

            .then(response => {

                return state(response.data?.PrivacyPolicyServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    addPrivacy(data, setPrivacy) {

        return RequestManager.post(`${baseUrl}legal/docs/privacy-policy/store`, data)

            .then(response => {

                const newItem = response.data?.PrivacyPolicyServices;

                setPrivacy(perv => ([...perv, newItem]));

                Swal.success('تم بنجاح!', `تم الأضافة بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateTerm(id, data) {

        return RequestManager.update(`${baseUrl}legal/docs/privacy-policy/update/${id}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deletePrivacy(privacyId, list, setList) {

        return RequestManager.delete(`${baseUrl}legal/docs/privacy-policy/delete/${privacyId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != privacyId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم الحذف`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    Privacy
}