import { handleLogOut } from "../../assets/js/utils";
import { RequestManager, Swal, baseUrl } from "../data";

class Questions {

    fetchQuestions(state) {

        return RequestManager.get(`${baseUrl}legal/docs/frequently-asked-question`)

            .then(response => {

                return state(response.data?.FrequentlyAskedQuestionServices);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }
    addQuestions(data, setQuestions) {

        return RequestManager.post(`${baseUrl}legal/docs/frequently-asked-question/store`, data)

            .then(response => {

                const newItem = response.data?.FrequentlyAskedQuestionServices;

                setQuestions(perv => ([...perv, newItem]));

                Swal.success('تم بنجاح!', `تم اضافه الشرط بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    updateQuestion(id, data) {

        return RequestManager.update(`${baseUrl}legal/docs/frequently-asked-question/update/${id}`, data, 'put')

            .then(response => {

                Swal.success('تم بنجاح!', `تم تعديل البينات بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }

    deleteQuestions(questionId, list, setList) {

        return RequestManager.delete(`${baseUrl}legal/docs/frequently-asked-question/delete/${questionId}`)

            .then(response => {

                const removeItemFromList = list.filter(item => item?.id != questionId);

                setList(removeItemFromList);

                return Swal.success('تم بنجاح!', `تم حذف السؤال بنجاح`);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `something wrong please try again later`).then(_ => handleLogOut(error?.response));

            });

    }


}

export {
    Questions
}