import { useContext } from 'react';
import './assets/style/App.css';
import { MainPanel } from './layouts';
import useCustomEffect from './hooks/useCustomEffect';
import routes from './routes/routes';
import "aos/dist/aos.css";
import { AppContext } from './components/AppContext/AppContext';
import { Loader } from './routes/data';
import { Auth, ScrollReset, ScrollToTopBtn } from './components';
import { isLogin } from './assets/js/utils';


function App() {

  const { useAppControlar } = useCustomEffect();

  const { isRenderLayouts, setIsRenderLayouts, isExpanded, setIsExpanded } = useAppControlar();

  const { isLoading } = useContext(AppContext);

  const isLoggedIn = isLogin();

  return (
    <div className="App">

      {isLoading ? <Loader /> : null}

      <Auth />

      {isRenderLayouts && isLoggedIn ? <MainPanel isExpanded={isExpanded} setIsExpanded={setIsExpanded} /> : null}

      {routes(isRenderLayouts, setIsRenderLayouts, isLoggedIn, isExpanded, setIsExpanded)}

      <ScrollReset />

      <ScrollToTopBtn />

    </div>
  );
}

export default App;
