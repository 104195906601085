import { PanelMenu } from 'primereact/panelmenu';
import { items } from "./data"
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import home from "../../../../assets/SVGS/home.svg";
import grid from "../../../../assets/SVGS/grid.svg";

export default function MnueMainContent({ data }) {

    const linkStyle = `w-full overflow-x-hidden rounded-[6px] flex items-center p-3 !text-black font-medium`;

    return (

        <div className={`main-menu-content relative h-full overflow-y-auto pb-10 max-h-[84vh]`}>

            <p className='text-[11px] text-[var(--blue-bell)] absolute left-4 top-0 '>الاساسية</p>

            <ul>

                <li className={`px-2`}>

                    <NavLink to={''} className={linkStyle}>

                        <img src={home} alt='' width={'19px'} height={'19px'} />

                        <span className='ms-4 font-[700]' style={{ fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"` }}>لوحة التحكم</span>

                    </NavLink>

                </li>

                <li className={`px-2`}>

                    <NavLink to={'employees'} className={linkStyle}>

                        <img src={grid} alt='' width={'19px'} height={'19px'} />

                        <span className='ms-4 font-[700]' style={{ fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"` }}>الأدمين</span>

                    </NavLink>

                </li>

                <li className={`px-2 mb-8 relative flex items-center`}>

                    <NavLink to={'wallet'} className={linkStyle}>

                        <FontAwesomeIcon icon={faDollarSign} className='w-[19px] text-[#4C4C4C]' />

                        <span className='ms-4 font-[700]' style={{ fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"` }}>البنك</span>

                    </NavLink>

                    <div className='!w-fit !min-w-[30px] h-[30px] absolute left-2 !rounded-full bg-[var(--primary)] text-white flex items-center justify-center'>{data?.pendingTransferReques}</div>

                </li>

                <li className={`px-2`}>

                    <PanelMenu model={items(data)} multiple={true} className="w-full" />

                </li>

            </ul>
        </div >
    )
}
